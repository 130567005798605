export const setAudiovisualFetchStatusAction = (status) => {
    return { type: 'AUDIOVISUAL_SET_FETCH_STATUS_ACTION', payload: status }
}

export const setAudiovisualFetchResultsAction = (results) => {
    return { type: 'AUDIOVISUAL_SET_FETCH_RESULTS_ACTION', payload: results }
}

export const setAudiovisualCurrentPageAction = (currentPage) => {
    return { type: 'AUDIOVISUAL_SET_PAGINATION_CURRENT_PAGE', payload: currentPage }
}

export const setAudiovisualTotalPagesAction = (totalPages) => {
    return { type: 'AUDIOVISUAL_SET_PAGINATION_TOTAL_PAGES', payload: totalPages }
}

export const setAudiovisualRowsAction = (rowCount) => {
    return { type: 'AUDIOVISUAL_SET_PAGINATION_ROWS', payload: rowCount }
}

export const setAudiovisualFirstAction = (first) => {
    return { type: 'AUDIOVISUAL_SET_PAGINATION_FIRST', payload: first }
}

export const setAudiovisualFilterAction = (filter) => {
    return { type: 'AUDIOVISUAL_SET_FILTER', payload: filter }
}

export const setAudiovisualSortAction = (sort) => {
    return { type: 'AUDIOVISUAL_SET_SORT', payload: sort }
}

export const setAudiovisualExcelExportAction = () => {
    return { type: 'AUDIOVISUAL_SET_EXPORT_EXCEL' }
}

export const seAudiovisualDraftResultsAction = (results) => {
    return { type: 'AUDIOVISUAL_SET_DRAFT_RESULTS', payload: results }
}

export const clearAudiovisualDraftResultsAction = (result) => {
    return { type: 'AUDIOVISUAL_CLEAR_DRAFT_RESULTS', payload: result }
}

export const clearAudiovisualFullDraftResultsAction = () => {
    return { type: 'AUDIOVISUAL_CLEAR_FULL_DRAFT_RESULTS' }
}

export const setEpisodeListUpdateStatusAction = (status) => {
    return { type: 'AUDIOVISUAL_SET_EPISODELISTUPDATE_STATUS_ACTION', payload: status }
}
export const setShowDataUpdateAction = (show) => {
    return { type: 'AUDIOVISUAL_SET_SHOW_DATA_UPDATE', payload: show }
}

export const setDataUpdateKeyAction = (key) => {
    return { type: 'AUDIOVISUAL_SET_DATA_UPDATE_KEY', payload: key }
}
//To enable / disable SAVE when in edit mode

export const episodeListstartEditing = () => {
    return { type: 'AUDIOVISUAL_EPISODELIST_START_EDITING' }
}

export const episodeListstopEditing = () => {
    return { type: 'AUDIOVISUAL_EPISODELIST_STOP_EDITING' }
}





