import moment from 'moment';

const unknownDate = 'unknown date'

export function toGbpCurrency(amount) {
    return amount.toLocaleString('en-GB', {
        style: 'currency',
        currency: 'GBP'
    })
}

export function toDate(date) {
    const submittedDate = new Date(date)

    return submittedDate.toLocaleDateString('en-GB')
}


const formatDateBase = (dateString, format) => {

    if (!dateString) {
        return unknownDate
    }

    const date = new Date(dateString)
    const year = date.getFullYear()

    if (year < 1000) {
        return unknownDate
    }

    const mDate = moment(date)
    return mDate.format(format)
}

export function formatDateMonth(dateString) {
    return formatDateBase(dateString, 'Do MMMM YYYY')

}
export function formatDateMonthTime(dateString) {

    return formatDateBase(dateString, 'HH:mm Do MMMM YYYY')
}


export function moveEntryToTop(customlist, targetId) {
    // Find the index of the entry with id = targetId
    const index = customlist.findIndex(item => item.id === targetId)

    if (index !== -1) {
        // Remove the entry from its original position
        const [entry] = customlist.splice(index, 1)
        // Add the entry to the top of the list
        customlist.unshift(entry)
    }
    return customlist
}

export function convertMinutesToDuration(minutes) {
    const hours = Math.floor(minutes / 60)
    const mins = minutes % 60
    return `${hours}h ${mins}m`
}
