import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'primereact/button'
import { push } from 'redux-first-history'
import { validateProgrammeChanges } from 'components/Body/data-entry/AudioVisual/validations'
import { confirmPopup } from 'primereact/confirmpopup'
import { SplitButton } from 'primereact/splitbutton'
import { selectEpisodeResultPresentation, selectEpisodeDraftHasDeletedTransmissions, selectAudioVisualResultHasChanges ,selectIsNewEpisode} from 'components/Body/data-entry/AudioVisual/store/selectors'
import { setIsNewEpisodeAction, setShowDataUpdateAction, setShowDataCreateAction, setDataUpdateKeyAction, setProgrammeCreateStatusAction, setProgrammeUpdateStatusAction, setEpisodeDraftResultsTransmissionsAction, clearEpisodeResultsTransmissionsFromDraftsAction, clearProgrammeDraftResultsAction, clearProgrammeDraftResultsEpisodesAction } from 'components/Body/data-entry/AudioVisual/store/actions'

// ReSharper disable once InconsistentNaming
export const ProgrammeDetailsFooter = ({ programmeGuid, isNewProgramme, toast, resultsDataForEdit, primaryTitle, episodePrimaryTitle, programmeTypeId, formatId, primaryCountry, episodeValidationRequired, isFilm, programmeDataOriginal }) => {
    const draftAvailable = useSelector(selectAudioVisualResultHasChanges)
    const deleteTransmissionAvailable = useSelector(selectEpisodeDraftHasDeletedTransmissions)
    var episodeData = useSelector(selectEpisodeResultPresentation)
    const isNewEpisodeFlag = useSelector(selectIsNewEpisode)

    const dispatch = useDispatch()
    if (isNewEpisodeFlag) { //TODO : with redux store
        if (episodeData && episodeData.id) {
            const episodesArray = Object.values(programmeDataOriginal?.episodes || {});
            const episodeExists = episodesArray.find(episode => episode.id === episodeData.id);
            if (episodeExists) {
                dispatch(setIsNewEpisodeAction(false))
            }
        }
    }
  
    const navigateTo = route => {
        dispatch(push(route)) // Dispatch action to navigate to the specified route
    }
    const clearEpisodeDraftResultsTransmissions = () => dispatch(clearEpisodeResultsTransmissionsFromDraftsAction())
    const setEpisodeDraftResultsTransmissions = (transmissions) => dispatch(setEpisodeDraftResultsTransmissionsAction(transmissions))
    const clearProgrammeDraftResults = () => dispatch(clearProgrammeDraftResultsAction())
    const clearProgrammeEpisodeDraftResults = () => dispatch(clearProgrammeDraftResultsEpisodesAction())
    const setShowDataUpdate = (show) => dispatch(setShowDataUpdateAction(show))
    const setShowDataCreate = (show) => dispatch(setShowDataCreateAction(show))
    const setDataUpdateKey = (key) => dispatch(setDataUpdateKeyAction(key))

    //On direct click of Accept in split button
    const splitSave = (event) => {
        //transmission delete confirmation
        if (deleteTransmissionAvailable) {
            confirmPopup({
                target: event.currentTarget,
                message: 'You are about to delete Transmissions. Do you want to continue?',
                icon: 'pi pi-info-circle',
                acceptClassName: 'p-button-danger',
                accept: acceptSave,
                reject: rejectTransmissionChange
            })
        }
        else {
            acceptSave()
        }
    }

    const acceptSave = () => {
        if (!validateProgrammeChanges(toast, dispatch, resultsDataForEdit, primaryTitle, programmeTypeId, formatId, primaryCountry, episodeData, episodePrimaryTitle, episodeValidationRequired)) {
            return
        }
        if (isNewProgramme) {
            handleCreateTrigger()
        }
        else {
            handleUpdateTrigger()
        }

    }

    const rejectTransmissionChange = () => {
        clearEpisodeDraftResultsTransmissions()
        dispatch(setEpisodeDraftResultsTransmissions(episodeData?.transmissions))

    }

    //Accept and reject for Save cancellation popup

    const accept = () => {
        if (!draftAvailable) {
            navigateTo(`/AudioVisual`)
        }
        try {
            clearProgrammeDraftResults()
            clearProgrammeEpisodeDraftResults()
            toast.current.show({
                severity: 'info',
                summary: 'Confirmed',
                detail: 'Programme Changes aborted',
                life: 3000
            })
            navigateTo(`/AudioVisual`)
        } catch (error) {
            console.error('Error in accept function:', error)
        }
    }

    //Cancel Save 

    const showCancelConfirm = (event) => {
        if (draftAvailable) {
            confirmPopup({
                target: event.currentTarget,
                message: 'You have unsaved changes. Do you want to discard them?',
                icon: 'pi pi-info-circle',
                defaultFocus: 'reject',
                acceptClassName: 'p-button-danger',
                accept
            })
        }
        else {
            navigateTo(`/AudioVisual`)
        }
    }

    //Accept Split Buttom Items
    const saveModalItems = [
        {
            label: 'Save',
            icon: 'fa-light fa-check',
            command: (event) => {
                if (deleteTransmissionAvailable) {
                    confirmPopup({
                        target: event.currentTarget,
                        message: 'You are about to delete Transmissions. Do you want to continue?',
                        icon: 'pi pi-info-circle',
                        acceptClassName: 'p-button-danger',
                        accept: acceptSave,
                        reject: rejectTransmissionChange
                    })
                }
                else {
                    if (!validateProgrammeChanges(toast, dispatch, resultsDataForEdit, primaryTitle, programmeTypeId, formatId, primaryCountry, episodeData, episodePrimaryTitle, episodeValidationRequired)) {
                        return
                    }
                    if (isNewProgramme) {
                        handleCreateTrigger()
                    }
                    else {
                        handleUpdateTrigger()
                    }
                }

            }
        },
        {
            label: 'Save and Exit',
            icon: 'fa-light fa-check',
            command: (event) => {
                if (deleteTransmissionAvailable) {
                    confirmPopup({
                        target: event.currentTarget,
                        message: 'You are about to delete Transmissions. Do you want to continue?',
                        icon: 'pi pi-info-circle',
                        acceptClassName: 'p-button-danger',
                        accept: acceptSave,
                        reject: rejectTransmissionChange
                    })
                }
                else {
                    if (!validateProgrammeChanges(toast,
                        dispatch,
                        resultsDataForEdit,
                        primaryTitle,
                        programmeTypeId,
                        formatId,
                        primaryCountry,
                        episodeData,
                        episodePrimaryTitle,
                        episodeValidationRequired)) {
                        return
                    }
                    if (isNewProgramme) {
                        handleCreateTrigger()
                        if (!isFilm) {
                            setTimeout(() => {
                                    navigateTo('/AudioVisual')
                                    window.location.reload()
                                },
                                1000);
                        }
                        else {
                            setTimeout(() => {
                                    navigateTo('/AudioVisual')
                                },
                                1000);
                        }
                    }
                    else {
                        handleUpdateTrigger()
                        if (!isFilm) {
                            setTimeout(() => {
                                    navigateTo('/AudioVisual')
                                    window.location.reload()
                                },
                                1000);
                        }
                        else {
                            setTimeout(() => {
                                    navigateTo('/AudioVisual')
                                },
                                1000);
                        }
                    }
                }

            }
        }
    ]

    //handle update by incrementing the key
    const handleUpdateTrigger = () => {
        dispatch(setProgrammeUpdateStatusAction('idle'))
        setShowDataUpdate(false)
        setDataUpdateKey(prevKey => prevKey + 1) // Increment the key to force re-render
        setShowDataUpdate(true)
    }

    //handle update by incrementing the key
    const handleCreateTrigger = () => {
        dispatch(setProgrammeCreateStatusAction('idle'))
        setShowDataCreate(false)
        setDataUpdateKey(prevKey => prevKey + 1) // Increment the key to force re-render
        setShowDataCreate(true)
    }

    return (
        <div className="app-footer">
            <SplitButton className='green mr-2 mt-2' label="Save" icon="fa-light fa-check" onClick={splitSave} dropdownIcon="fa-light fa-chevron-down" model={saveModalItems} disabled={ isNewEpisodeFlag } />
            <Button label="Cancel" icon="fa-light fa-xmark" className="yellow mt-2 mr-3 " raised onClick={showCancelConfirm} ></Button>
        </div>
    )
}