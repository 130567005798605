import React, { forwardRef, useState, useEffect, useRef } from 'react';
import { InputText } from 'primereact/inputtext'
import { Calendar } from 'primereact/calendar'
import { formatDateMonthTime } from 'modules'
import { Button } from 'primereact/button'
import { OtherTitles } from './OtherTitles'
import { useDispatch } from 'react-redux'
import { selectShowEpisodeDataUpdate, selectShowEpisodeDataCreate, selectEpisodeDataUpdateKey, selectEpisodeFetchRequest, selectEpisodeUpdateRequest, selectEpisodeResultUpdateStatus, selectEpisodeResultOriginal, selectEpisodeResultPresentation, selectEpisodeGuid } from 'components/Body/data-entry/AudioVisual/store/selectors'
import { useSelector } from 'react-redux'
import 'assets/css/alcs-theme.css'
import { setIsNewEpisodeAction, setShowEpisodeDataUpdateAction, setShowEpisodeDataCreateAction, setIsNewProgrammeAction, setEpisodeGuidAction, setProgrammeDraftResultsSapCodeAction, setEpisodeFetchStatusAction, setEpisodeDraftResultNumberAction, setEpisodeDraftResultSeriesAction, clearEpisodeDraftResultsNumberAction, clearEpisodeDraftResultsSeriesAction, setEpisodeDraftResultsTitleAction, setEpisodeUpdateStatusAction, clearProgrammeDraftResultsEpisodesAction, setProgrammeOriginalAggregateVersionAction, setEpisodeFetchResultsAction, setEpisodeDraftResultDurationAction, clearEpisodeDraftResultsDurationAction, setEpisodeDraftResultIdaAction, clearEpisodeDraftResultsIdaAction, setEpisodeDraftResultYearOfProductionAction, clearEpisodeDraftResultsYearOfProductionAction } from 'components/Body/data-entry/AudioVisual/store/actions'
import DataEntry from 'api/DataEntry/AudioVisual'
import { TabView, TabPanel } from 'primereact/tabview'
import { Contributions } from './Contributions'
import { Transmissions } from './Transmissions'
import DataUpdate from 'api/DataUpdate/AudioVisual'
import { Toast } from 'primereact/toast'
import { CopyControl } from "../../../common/CopyControl"
import DataCreate from 'api/DataCreate/AudioVisual'
import { EpisodeDetailsFooter } from 'components/Body/data-entry/AudioVisual/EpisodeDetailsFooter'

// ReSharper disable once InconsistentNaming
export const EpisodeDetailsContent = forwardRef((props, ref) => {
    const dispatch = useDispatch()
    const toast = useRef(null)
    const { id, episodeId, resultsData, programmePrimaryTitle, otherProgrammeTitles, languages } = props
    const episodeGuid = useSelector(selectEpisodeGuid)
    const isEpisodeDataRequired = episodeId && episodeGuid !== episodeId
    if (isEpisodeDataRequired) dispatch(setEpisodeGuidAction(episodeId))
    const [isEditingTitle, setIsEditingTitle] = useState(false)
    const otherTitlesRef = useRef(null);
    const cancelConfirmRef = useRef(null);

    /* Dispatch */

    const setAcceptedDuration = (duration) => dispatch(setEpisodeDraftResultDurationAction(duration))
    const clearAcceptedDuration = (duration) => dispatch(clearEpisodeDraftResultsDurationAction(duration))
    const setAcceptedIda = (ida) => dispatch(setEpisodeDraftResultIdaAction(ida))
    const clearAcceptedIda = (ida) => dispatch(clearEpisodeDraftResultsIdaAction(ida))
    const setAcceptedYear = (year) => dispatch(setEpisodeDraftResultYearOfProductionAction(year))
    const clearAcceptedYear = (year) => dispatch(clearEpisodeDraftResultsYearOfProductionAction(year))
    const clearProgrammeEpisodeDraftResults = () => dispatch(clearProgrammeDraftResultsEpisodesAction())
    const setAcceptedTitles = (titlesToAccept) => dispatch(setEpisodeDraftResultsTitleAction(titlesToAccept))
    const setAcceptedSeries = (series) => dispatch(setEpisodeDraftResultSeriesAction(series))
    const clearAcceptedSeries = (series) => dispatch(clearEpisodeDraftResultsSeriesAction(series))
    const setAcceptedNumber = (number) => dispatch(setEpisodeDraftResultNumberAction(number))
    const clearAcceptedNumber = (number) => dispatch(clearEpisodeDraftResultsNumberAction(number))
    const setShowDataUpdate = (show) => dispatch(setShowEpisodeDataUpdateAction(show))
    const setShowDataCreate = (show) => dispatch(setShowEpisodeDataCreateAction(show))
    const setIsNewEpisode = (isNew) => dispatch(setIsNewEpisodeAction(isNew))
    const updateStatus = useSelector(selectEpisodeResultUpdateStatus)
    const showDataUpdate = useSelector(selectShowEpisodeDataUpdate)
    const showDataCreate = useSelector(selectShowEpisodeDataCreate)
    const dataUpdateKey = useSelector(selectEpisodeDataUpdateKey)
    var episodeData = useSelector(selectEpisodeResultPresentation)
    var episodeOriginalData = useSelector(selectEpisodeResultOriginal)
    let episodePrimaryTitle = episodeData && episodeData.titles && episodeData.titles.length > 0 && episodeData.titles.find(title => title.titleTypeId === 1)
    const validFilmFormatIds = [1, 5]
    const isFilm = resultsData && validFilmFormatIds.includes(resultsData.formatId)
    const programmeTitle = programmePrimaryTitle && programmePrimaryTitle.title
    let altEpisodeTitles = isFilm ? otherProgrammeTitles : (episodeData && episodeData.titles && episodeData.titles.length > 0 && episodeData.titles.filter(title => title.titleTypeId !== 1))
    const isNewEpisode = episodeData.sapCode === null || episodeData.sapCode === ''
    const [newTitle, setNewTitle] = useState(episodePrimaryTitle ? episodePrimaryTitle.title : '')
    const [initialTitle, setInitialTitle] = useState(false)
    if (!initialTitle) {
        if (newTitle === '' && episodePrimaryTitle) {
            setNewTitle(episodePrimaryTitle.title)
            setInitialTitle(true)
        }
    }

    useEffect(() => {
        if (updateStatus === 'complete') {
            dispatch(setEpisodeUpdateStatusAction({ updateStatus: 'idle' }))
            dispatch(setEpisodeFetchResultsAction(episodeData))
            setShowDataUpdate(false)
            clearProgrammeEpisodeDraftResults()
            toast.current.show({ severity: 'success', summary: 'Success', detail: 'Update successful', life: 3000 })
            setIsNewEpisode(false)
            setTimeout(() => {
                dispatch(setEpisodeGuidAction(''))
            }, 1000);


        } else if (updateStatus?.status === 'error') {
            const errorMessage = updateStatus?.status?.error?.Message || 'An unknown error occurred'
            dispatch(setEpisodeUpdateStatusAction({ updateStatus: 'idle' }))
            setShowDataUpdate(false)
            toast.current.show({ severity: 'error', summary: 'Error', detail: `Error: ${errorMessage}`, life: 3000 })

        }
    }, [updateStatus]);


    const showCancelConfirm = (event) => {
        if (cancelConfirmRef.current) {
            cancelConfirmRef.current.showCancelConfirm(event)
        }
    }
    const handleCreateComplete = () => {
        setShowDataCreate(false)
    }


    const handleDurationChange = (e) => {
        const newDuration = e.target.value;
        if (episodeOriginalData.durationMinutes === null && newDuration === "") {
            clearAcceptedDuration()
        }
        else if (newDuration === String(episodeOriginalData.durationMinutes)) {
            clearAcceptedDuration()
        }
        else {
            setAcceptedDuration(newDuration)
        }
    }

    const handleIdaChange = (e) => {
        const newIda = e.target.value;
        if (episodeOriginalData.idaCode === null && newIda === "") {
            clearAcceptedIda()
        }
        else if (newIda === String(episodeOriginalData.idaCode)) {
            clearAcceptedIda()
        }
        else {
            setAcceptedIda(newIda)
        }
    }

    const handleSeriesChange = (e) => {
        const newSeries = e.target.value;
        if (episodeOriginalData.series === null && newSeries === "") {
            clearAcceptedSeries()
        }
        else if (newSeries === String(episodeOriginalData.series)) {
            clearAcceptedSeries()
        }
        else {
            setAcceptedSeries(newSeries)
        }
    }

    const handleNumberChange = (e) => {
        const newEpisodeNumber = e.target.value;
        if (episodeOriginalData.number === null && newEpisodeNumber === "") {
            clearAcceptedNumber()
        }
        else if (newEpisodeNumber === String(episodeOriginalData.number)) {
            clearAcceptedNumber()
        }
        else {
            setAcceptedNumber(newEpisodeNumber)
        }
    }

    const handleYearChange = (e) => {
        const newDate = new Date(e.target.value)
        const newYear = newDate.getFullYear()

        if (episodeOriginalData.yearOfProduction === null && newYear === "") {
            clearAcceptedYear()
        }
        else if (newYear === episodeOriginalData.yearOfProduction) {
            clearAcceptedYear()
        }
        else {
            setAcceptedYear(newYear)
        }
    }

    const handleEditTitle = () => {
        setIsEditingTitle(true);
    }

    const handleSavePrimaryTitle = () => {
        // Add logic to save the new title
        setIsEditingTitle(false);
        const titlesArray = Object.values(episodeData.titles)
        const originalObjectIndex = titlesArray.findIndex(title => title.titleTypeId === 1);
        titlesArray[originalObjectIndex].title = newTitle
        setAcceptedTitles(titlesArray)
    }

    const handleTitleChange = (e) => {
        setNewTitle(e.target.value);
    }

    const handleUpdateComplete = () => {
        setShowDataUpdate(false)
    }

    if (isEpisodeDataRequired) {
        return (<DataEntry
            id={id + '_DataEntry_audioVisual'}
            key={id}
            setNewProgrammeStatus={setIsNewProgrammeAction}
            setStatusAction={setEpisodeFetchStatusAction} // Use the provided action for fetching results
            setOriginalResultAction={setEpisodeFetchResultsAction}
            requestSelector={selectEpisodeFetchRequest}
        />)
    }

    const renderTitles = () => {
        if (isEditingTitle) {
            return (
                <div className="mb-3 d-flex align-items-center gap-3">
                    <div>
                        <InputText value={newTitle} onChange={handleTitleChange} />
                        <Button icon="pi pi-check" className="p-button-text" rounded onClick={handleSavePrimaryTitle} />
                    </div>
                    <span className="codes-style">Episode Code: {episodeData && episodeData.sapCode ? episodeData.sapCode : 'Pending'} </span>
                    <CopyControl content={episodeData && episodeData.sapCode} />
                </div>
            )
        }
        return (
            <div className="mb-3 d-flex align-items-center gap-3">
                <span className="mr-2 episode-bold-titles align-middle"> {isFilm ? programmeTitle : episodePrimaryTitle && episodePrimaryTitle.title}
                </span>
                <CopyControl content={isFilm ? programmeTitle : episodePrimaryTitle && episodePrimaryTitle.title} />
                {!isFilm && (
                    <Button icon="pi pi-pencil" className="p-button-text" rounded onClick={handleEditTitle} className="ml-2" />
                )}
                <span className="codes-style">Episode Code: {episodeData && episodeData.sapCode ? episodeData.sapCode : 'Pending'} </span>
                <CopyControl content={episodeData && episodeData.sapCode} />
            </div>
        )
    }

    return (
        <div className="episode-panel-wrapper d-flex flex-column">
            <div className="episode-panel col-12 d-flex flex-column position-relative">
                {showDataUpdate && (
                    <DataUpdate
                        id={props.id + '_DataUpdate_audioVisual_Episode'}
                        key={dataUpdateKey}
                        setStatusAction={setEpisodeUpdateStatusAction}
                        requestSelector={selectEpisodeUpdateRequest}
                        setAggregateAction={setProgrammeOriginalAggregateVersionAction}
                        onComplete={handleUpdateComplete}
                        episodeOnly={true}
                    />
                )}
                {showDataCreate && (
                    <DataCreate
                        id={props.id + '_DataCreate_audioVisual_episode'}
                        key={dataUpdateKey}
                        setStatusAction={setEpisodeUpdateStatusAction}
                        setSapCodeAction={setProgrammeDraftResultsSapCodeAction}
                        setAggregateAction={setProgrammeOriginalAggregateVersionAction}
                        requestSelector={selectEpisodeUpdateRequest}
                        setNewProgrammeStatus={setIsNewProgrammeAction}
                        onComplete={handleCreateComplete}
                        episodeOnly={true}
                    />
                )}

                <Toast ref={toast} />
                <div className="header d-flex justify-content-between align-items-center p-3" >
                    <div className="d-flex align-items-center gap-3">
                        <span className="bold-titles mr-1" >
                            {programmeTitle}
                        </span>
                        <CopyControl className="ml-1" bgcolor="" content={programmeTitle} />
                        <span className="codes-style">
                            Programme Code: {resultsData && resultsData.sapCode ? resultsData.sapCode : 'Pending'}
                        </span>
                        <CopyControl className="ml-1" bgcolor="" content={resultsData && resultsData.sapCode} />
                    </div>
                    {!isFilm && (
                        <Button icon="fa-light fa-xmark" rounded text severity="success" className="white" onClick={showCancelConfirm} />
                    )}
                </div>
                <div className="content p-3">
                    <div className="row">
                        <div className='col-7'>
                            {renderTitles()}
                            <div className="row mb-4">
                                <div className="col-md-2">
                                    <div className="d-flex flex-column">
                                        <p className="label-input mb-1">Episode No.</p>
                                        <InputText id="episodeNumber" value={isFilm ? 'NA' : episodeData && episodeData.number} onChange={handleNumberChange} disabled={isFilm} />
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="d-flex flex-column">
                                        <p className="label-input mb-1">Series No.</p>
                                        <InputText id="seriesNumber" value={isFilm ? 'NA' : episodeData && episodeData.series} onChange={handleSeriesChange} disabled={isFilm} />
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="d-flex flex-column">
                                        <p className="label-input mb-1">Duration</p>
                                        <InputText id="duration" value={episodeData && episodeData.durationMinutes} onChange={handleDurationChange} />

                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="d-flex flex-column">
                                        <p className="label-input mb-1">Year Produced</p>
                                        <Calendar id="yearOfProduction" keyfilter="int" value={(episodeData && episodeData.yearOfProduction) ? new Date(episodeData.yearOfProduction, 0, 1) : null} onChange={handleYearChange} view="year" dateFormat="yy" />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="d-flex flex-column">
                                        <p className="label-input mb-1">
                                            Episode IDA Number
                                        </p>
                                        <div className="mb-3 d-flex align-items-center gap-3">
                                            <InputText className="mr-1" id="episodeIDA" keyfilter="int" value={episodeData && episodeData.idaCode} onChange={handleIdaChange} />
                                            <CopyControl content={(episodeData && episodeData.idaCode) || ''} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='sub-section'>
                                <fieldset className="mt-2">
                                    <legend>History</legend>
                                </fieldset>
                            </div>
                            <div className='row mb-5'>
                                <div className="col-md-4">
                                    <div className="label-value">
                                        <span className="details-dashboard-label-in episode-history-label" >Created By:</span>
                                        <span className="details-dashboard-data-in episode-history-data">
                                            {episodeData && episodeData.createdBy !== 'Default' ? episodeData.createdBy : 'UNKNOWN'}
                                        </span>
                                    </div>
                                    <div className="label-value">
                                        <span className="details-dashboard-label-in episode-history-label">Date Created:</span>
                                        <span className="details-dashboard-data-in episode-history-data" >{episodeData && !isNewEpisode && formatDateMonthTime(episodeData.createdOn)}</span>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="label-value">
                                        <span className="details-dashboard-label-in episode-history-label" >Modified By:</span>
                                        <span className="details-dashboard-data-in episode-history-data" >
                                            {episodeData && episodeData.updatedBy !== 'Default' ? episodeData.updatedBy : 'UNKNOWN'}
                                        </span>
                                    </div>
                                    <div className="label-value">
                                        <span className="details-dashboard-label-in episode-history-label" >Last Modified:</span>
                                        <span className="details-dashboard-data-in episode-history-data" >{episodeData && !isNewEpisode && formatDateMonthTime(episodeData.updatedOn)}</span>
                                    </div>
                                </div>
                                <div className="col-md-1" />
                                <div className="col d-flex">
                                    <Button label="View History" icon="fa-solid fa-clock-rotate-left" raised disabled />
                                </div>
                            </div>
                        </div>
                        <div className="col-5 sub-section">
                            <OtherTitles altEpisodeTitles={altEpisodeTitles} isFilm={isFilm} ref={otherTitlesRef} languages={languages} />
                        </div>
                    </div>
                    <div>
                        <div className='sub-section mt-0'>
                            <fieldset>
                                <legend>Other Information</legend>
                            </fieldset>
                        </div>
                        <TabView className='custom-content custom-tab-panel'>
                            <TabPanel header="Contributors" leftIcon="fa-light fa-user-group mr-2">
                                <p className="m-0" >
                                    {episodeData && episodeData.contributions && <Contributions isFilm={isFilm} />}
                                </p>
                            </TabPanel>
                            <TabPanel header="Transmissions" leftIcon="fa-light fa-satellite-dish mr-2" className='custom-tab-panel'>
                                <p className="m-0" >
                                    <Transmissions isFilm={isFilm} />
                                </p>
                            </TabPanel>
                            <TabPanel header="Undistributed Royalty" leftIcon="fa-light fa-money-check-pen mr-2">
                                <p className="m-0">
                                    Undistributed Royalty
                                </p>
                            </TabPanel>
                        </TabView>
                    </div>
                </div>

            </div>

            <div className="episode-details-footer" hidden>
                <EpisodeDetailsFooter cancelConfirmRef={cancelConfirmRef} episodeData={episodeData} episodePrimaryTitle={episodePrimaryTitle} toast={toast} isFilm={isFilm} isNewEpisode={isNewEpisode} />
            </div>

        </div>
    )
})