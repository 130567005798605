export const audioVisualReasultsInitialState = {
    status: 'idle',
    results: {

    },
    currentPage: 1,
    visiblePages: [],
    totalPages: 0,
    row: 50,
    first: 0,
    sort: null,
    filter: null,   
    updateEpisodeListStatus: { updateEpisodeListStatus: 'idle' },
    showEpisodeDataUpdate: false,   
    episodeDataUpdateKey: 0,
    episodeListEditRowCount: 0
}

export const audioVisualResultsReducer = (state = audioVisualReasultsInitialState, action) => {
    switch (action.type) {
        case 'AUDIOVISUAL_SET_FETCH_STATUS_ACTION':
            return {
                ...state,
                status: action.payload
            }
        case 'AUDIOVISUAL_SET_FETCH_RESULTS_ACTION':
            return {
                ...state,
                results: action.payload
            }
        case 'AUDIOVISUAL_SET_PAGINATION_CURRENT_PAGE':
            return {
                ...state,
                currentPage: action.payload
            }
        case 'AUDIOVISUAL_SET_PAGINATION_TOTAL_PAGES':
            return {
                ...state,
                totalPages: action.payload
            }
        case 'AUDIOVISUAL_SET_PAGINATION_ROWS':
            return {
                ...state,
                row: action.payload
            }
        case 'AUDIOVISUAL_SET_PAGINATION_FIRST':
            return {
                ...state,
                first: action.payload
            }
        case 'AUDIOVISUAL_SET_FILTER':
            return {
                ...state,
                filter: action.payload
            }
        case 'AUDIOVISUAL_SET_SORT':
            return {
                ...state,
                sort: action.payload
            }
        case 'AUDIOVISUAL_SET_EXPORT_EXCEL':
            return {
                ...state,
                excelExport: {
                    searchTerm: action.payload,
                    status: 'pending'
                }
            }
        case 'AUDIOVISUAL_SET_DRAFT_RESULTS':
            return {
                ...state,
                draft: {
                    ...state.draft || {},
                    ...action.payload
                }
            }
        case 'AUDIOVISUAL_CLEAR_DRAFT_RESULTS':
            {
                const episodes = { ...state.draft }
                delete episodes[action.payload]
                return {
                    ...state,
                    draft: episodes
                }
            }
        case 'AUDIOVISUAL_CLEAR_FULL_DRAFT_RESULTS':
            {
                const {
                    draft,
                    ...stateWithoutDraft
                } = state || {}
                return {                   
                     ...stateWithoutDraft                    
                }
            }
        case 'AUDIOVISUAL_SET_EPISODELISTUPDATE_STATUS_ACTION':
            {
                return {
                    ...state,
                    updateEpisodeListStatus: action.payload
                }
            }
        case 'AUDIOVISUAL_SET_SHOW_DATA_UPDATE': {
            return {
                ...state,
                showEpisodeDataUpdate: action.payload,
            }
        }      
        case 'AUDIOVISUAL_SET_DATA_UPDATE_KEY': {
            return {
                ...state,
                episodeDataUpdateKey: action.payload,
            }
        }
        case 'AUDIOVISUAL_EPISODELIST_START_EDITING':
            return {
                ...state,
                episodeListEditRowCount: state.episodeListEditRowCount + 1,
            }
        case 'AUDIOVISUAL_EPISODELIST_STOP_EDITING':
            return {
                ...state,
                episodeListEditRowCount: state.episodeListEditRowCount - 1,
            }
        default:
            return state
        }
}
