import React, { useState, useEffect } from 'react'
import StaticCommonGrid from 'components/common/StaticCommonGrid'
import { useSelector } from 'react-redux'
import {selectEpisodeResultOriginal} from 'components/Body/data-entry/AudioVisual/store/selectors'
import { FilterMatchMode } from 'primereact/api'
import { Button } from 'primereact/button';

export function Contributions(props) {
    const isFilm = props.isFilm
    var episodeData = useSelector(selectEpisodeResultOriginal)
    let contributions = episodeData && episodeData.contributions ? episodeData.contributions : []

    const [scrollHeight, setScrollHeight] = useState(calculateScrollHeight())

    //Andy L approves this useEffect
    useEffect(() => {
        const handleResize = () => {
            setScrollHeight(calculateScrollHeight())
        }
        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    //TODO: We should not be changing styles in JS. This should be done in CSS. The staticcommonGrid doesnot allow for this to be done in CSS
    function calculateScrollHeight() {
        const adjustedHeight = window.innerHeight / window.devicePixelRatio;
        let height = adjustedHeight * 0.2
        if (!isFilm) {
            height = height - 30
        }
        return `${height}px`
    } 
    const displayActions = function () {
        return (
            <div id={props.id + '_avList_ContributionGrid_actions'} className="d-flex justify-content-start align-items-center">
                <Button className="ml-2" icon="fa-light fa-pen" rounded disabled />             
                <Button className="ml-2" icon="fa-light fa-arrow-up-right" rounded disabled />
            </div>
        )
    }

    const header = (
        <div className="d-flex justify-content-end">
            <Button id={props.id + '_container_contribution_A%'} className="mr-3" label="A%" disabled/>
            <Button id={props.id + '_container_contribution_dataTable_export_excel'} className="mr-3" label="Export" icon="fa-light fa-file-import" disabled/>
            <Button id={props.id + '_container_contribution_add'} label="Add Contributor" icon="fa-light fa-plus" disabled/>                
        </div>
    )

    const contributionColumnDefinitions = [
        { title: 'Author Key', mapsToField: 'sapCode', filter: false, filterMatchMode: FilterMatchMode.STARTS_WITH, sortable: false, editable: false },
        { title: 'Contributor Name', mapsToField: 'contributorName', filter: false, filterMatchMode: FilterMatchMode.STARTS_WITH, sortable: false, editable: false },
        { title: 'Contribution', mapsToField: 'type', filter: false, filterMatchMode: FilterMatchMode.STARTS_WITH, sortable: false, editable: false },
        { title: '%', mapsToField: 'percentage', editable: false },
        { title: 'Minutes Worked', mapsToField: 'minutes', filter: false, filterMatchMode: FilterMatchMode.EQUALS, sortable: false, editable: false },
        { title: 'IPI No.', mapsToField: 'ipiNo', filter: false, filterMatchMode: FilterMatchMode.EQUALS, sortable: false, editable: false },
        { expand: true, title: 'Actions', mapsToField: 'Actions', template: displayActions }
    ]
    
    return (
        <div id={props.id + '_contributions'}>
            <StaticCommonGrid
                id={props.id + '_contributions_commonGrid'}
                keyColumnName="sapCode"
                scrollHeight={scrollHeight}
                data={contributions}
                totalCount={contributions.length}
                emptyMessage="No Contributions"
                columnDefinitions={contributionColumnDefinitions}
                showPaginator={false}
                header={header}
            />
        </div>
    )
}