import { createSelector } from 'reselect';
import { selectAvApiHost } from 'globalSelectors'
import { selectBody } from 'components/Body/store/selectors'
import { mergeEpisodeListChanges } from 'modules/data-entry/AudioVisual/dataTransforms'

export const selectBodyDatEntryApp = (store) => selectBody(store).dataEntry
export const selectBodyDataEntryAudioVisual = (store) => selectBodyDatEntryApp(store).audioVisualResults

const fetchRequest = (store, count, page, filter, sort, url) => ({ count, page, filter, sort, url, status: store.status })
const episodeListUpdateRequest = (status, url, episodeListDraft) => ({ endpoint: `${url}/ProgrammeEpisodesList`, status: status, episodeListDraft: episodeListDraft })


export const selectAudiovisualUrl = createSelector(
    [selectAvApiHost],
    host => `${host}/Programme`
)

export const selectAudiovisualCurrentPage = createSelector(
    [selectBodyDataEntryAudioVisual],
    (av) => av.currentPage
)

export const selectAudiovisualTotalPages = createSelector(
    [selectBodyDataEntryAudioVisual],
    (av) => av.totalPages
)

export const selectAudiovisualRows = createSelector(
    [selectBodyDataEntryAudioVisual],
    (av) => av.row
)

export const selectAudiovisualFirst = createSelector(
    [selectBodyDataEntryAudioVisual],
    (av) => av.first
)

export const selectAudiovisualFilter = createSelector(
    [selectBodyDataEntryAudioVisual],
    (av) => av.filter
)

export const selectAudiovisualSort = createSelector(
    [selectBodyDataEntryAudioVisual],
    (av) => av.sort
)

export const selectAudiovisualFetchRequest = createSelector(
    [selectBodyDataEntryAudioVisual, selectAudiovisualRows, selectAudiovisualCurrentPage, selectAudiovisualFilter, selectAudiovisualSort, selectAudiovisualUrl],
    fetchRequest
)

export const selectAudiovisualFetchResults = createSelector(
    [selectBodyDataEntryAudioVisual],
    (av) => av.results
)

export const selectAudiovisualFetchStatus = createSelector(
    [selectBodyDataEntryAudioVisual],
    (av) => av.status
)
export const selectAudiovisualDraft = createSelector(
    [selectBodyDataEntryAudioVisual],
    (av) => av.draft
)

export const selectAudioVisualResultHasChanges = createSelector(
    [selectAudiovisualDraft],
    (draft) => (draft != null && Object.keys(draft).length > 0)
)

export const selectEpisodeListResultPresentation = createSelector(
    [selectAudiovisualFetchResults, selectAudiovisualDraft],
    (original, draft) => {
        const merged = mergeEpisodeListChanges(original?.results, draft)
        console.log('Merged:', merged)
        return merged
    }
)
export const selectEpisodeListResultUpdateStatus = createSelector(
    [selectBodyDataEntryAudioVisual],
    (av) => av.updateEpisodeListStatus
)

export const selectEpisodeListUpdateRequest = createSelector(
    [selectEpisodeListResultUpdateStatus, selectAudiovisualUrl, selectAudiovisualDraft],
    episodeListUpdateRequest
)

export const selectShowEpisodeDataUpdate = createSelector(
    [selectBodyDataEntryAudioVisual],
    (av) => av.showEpisodeDataUpdate
)

export const selectEpisodeDataUpdateKey = createSelector(
    [selectBodyDataEntryAudioVisual],
    (av) => av.episodeDataUpdateKey
)
export const selectEpisodeListRowInEditCount = createSelector(
    [selectBodyDataEntryAudioVisual],
    (av) => av.episodeListEditRowCount
)



   

