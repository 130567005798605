import React, { useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { push } from 'redux-first-history' // Import push action creator
import { Dropdown } from 'primereact/dropdown'
import menuItems from 'components/Header/PlusMenuOptions.json'
import { setProgrammeFetchResultsAction, setIsNewProgrammeAction, setProgrammeGuidAction, setEpisodeGuidAction, setEpisodeFetchResultsAction } from 'components/Body/data-entry/AudioVisual/store/actions'

// ReSharper disable once InconsistentNaming
const PlusMenu = (props) => {
    const menu = useRef(null)
    const dispatch = useDispatch()
    const setNewProgramme = (newProgramme) => dispatch(setProgrammeFetchResultsAction(newProgramme))
    const setNewEpisode = (newEpisode) => dispatch(setEpisodeFetchResultsAction(newEpisode))
    const setIsNewProgramme = (status) => dispatch(setIsNewProgrammeAction(status))
    const setProgrammeGuid = (guid) => dispatch(setProgrammeGuidAction(guid))
    const setEpisodeGuid = (guid) => dispatch(setEpisodeGuidAction(guid))


    const handleMenuItemClick = (event) => {
        event.preventDefault()
        if (event.value === 'Programme') {
            const defaultProgramme = {
                id: crypto.randomUUID(),
                animeOrLive: { id: "LI " },
                formatId: "",
                isRadio: false,
                languageId: 22,
                programmeTypeId: "",
                sapCode: "",
                comment: "",
                version: 0,
                idAId: "",
                totalEpisodes: 1,
                productionCompanies: [],
                countries: {
                    1: { countryId: 1, isPrimary: true }
                }, 
                titles: [{
                    title: '',
                    languageId: 22,
                    titleTypeId: 1
                }],
                episodes: [{
                    id: crypto.randomUUID(),
                    durationMinutes: 0,
                    idaCode: "",
                    yearOfProduction: new Date().getFullYear(),
                    number: 0,
                    series: 0,
                    sapCode: "",
                    comments: "",
                    programmeSapCode: "",
                    titles: [{
                        title: "Episode One",
                        languageId: 22,
                        titleTypeId: 1
                    }],
                    transmissions: [
                    ],
                    createdOn: new Date().toISOString(),
                    updatedOn: new Date().toISOString()
                }],
                createdOn: new Date().toISOString(),
                updatedOn: new Date().toISOString()

            }           
            setIsNewProgramme(true)
            setProgrammeGuid(defaultProgramme?.id)
            setNewProgramme(defaultProgramme)
            const firstEpisodeId = defaultProgramme?.episodes?.[0]?.id
            const firstEpisode = defaultProgramme?.episodes?.[0]
            setEpisodeGuid(firstEpisodeId)
            setNewEpisode(firstEpisode)
            dispatch(push("/AudioVisual/" + defaultProgramme?.id + "/" + firstEpisodeId))
        }


    }

    // Most of the data in this component is the menu, which is written in PlusMenu.json
    //Option Group Label removed temporarily.  Will add back in when I have more than one group
    return (
        <span id={props.id} className={props.className}>
            <Dropdown
                options={menuItems}
                optionLabel="label"
                placeholder={<span><i className='fas fa-plus green'></i>Add/New</span>}
                dropdownIcon="pi pi-angle-down"
                onChange={(e) => handleMenuItemClick(e)}
                className="green"
                panelClassName="green"
            >
            </Dropdown>

        </span>
    )
}
export default PlusMenu