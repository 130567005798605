import { useRefAsState } from 'modules/React.Extensions'
import { useDispatch, useSelector } from 'react-redux'
import { authFetch } from 'api/auth/authFetch'
import { transformProgrammeEpisodeListDraftToEpisodeInfos } from 'modules/data-entry/AudioVisual/dataTransforms'

const updateEpisodeListDataByEndpoint = async (endpoint, episodeDraft, controller) => {
    let responseCode = -1

    try {
        const transformedBody = transformProgrammeEpisodeListDraftToEpisodeInfos(episodeDraft);
        const response = await authFetch(endpoint, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json'               
            },
            body: JSON.stringify(transformedBody),
            signal: controller.signal,
        })

        if (controller.signal.aborted) {
            return null
        }
        responseCode = response.status

        if (responseCode === 200) {
            const responseBody = await response.json();
            return { responseCode, responseBody };
        }

        throw {
            Message: 'The AV data update was not successful',
            StatusCode: responseCode,
            Details: await response.text()
        }
    } catch (error) {
        throw {
            Message: 'The AV data update was not successful',
            StatusCode: responseCode,
            Details: error,
        }
    }
}

const setUpEpisodeUpdate = (endpoint, episodeDraft) => {
    const controller = new AbortController()
    return {
        controller,
        update: () => updateEpisodeListDataByEndpoint(endpoint, episodeDraft, controller),
    }
}

// ReSharper disable once InconsistentNaming
const ProgrammeDataListUpdate = (props) => {
    const [abortController, setAbortController] = useRefAsState(null)

    const setStatusAction = props.setStatusAction
    const requestSelector = props.requestSelector
    const onComplete = props.onComplete
    //const setAggregate = props.setAggregateAction

    const dispatch = useDispatch()
    const setStatus = (newStatus) => dispatch(setStatusAction(newStatus))
   // const setNewAggregate = (newAggregate) => dispatch(setAggregate(newAggregate))

    const dataUpdateRequest = useSelector(requestSelector)
    const { endpoint, status, episodeListDraft } = dataUpdateRequest

    const handleUpdate = async () => {
        setStatus({ updateEpisodeListStatus: 'pending' })
        const { controller, update } = setUpEpisodeUpdate(endpoint, episodeListDraft)

        setAbortController(controller);

        try {
            const result = await update();
            if (result) {
                const { responseCode, responseBody } = result;
                if (responseCode === 200) {
                    const errors = responseBody.filter(response => response.statusCode !== 200);
                    if (errors.length > 0) {
                        console.error('Update failed:', errors);
                        setStatus({ updateEpisodeListStatus: 'error', responseBody });
                    } else {
                        console.log('Update successful:', responseBody);
                        setStatus({ updateEpisodeListStatus: 'complete', responseBody });
                        if (onComplete) {
                            setTimeout(() => {
                                onComplete();
                            }, 5000);
                        }
                    }
                } else {
                    console.error('Update failed:', responseBody);
                    setStatus({ updateEpisodeListStatus: 'error', error: responseBody });
                }
            }             
            
        } catch (error) {
            setStatus({ updateEpisodeListStatus: 'error', error: error })
        }
    }

    if (status.updateEpisodeListStatus === 'idle') {
        handleUpdate()
    }

    return null
}

export default ProgrammeDataListUpdate