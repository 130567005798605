
export const string = {
    isNullOrWhitespace: (value) => {
        if (value === undefined || value === null || value.trim() === '') {
            return true
        }
        else {
            return false
        }
        if (typeof value !== 'string') {
            throw { msg: 'Input is not of type string' }
        }
        return value === null || value.trim() === ''
    }
}