export const validateEpisodeListDetails = (toast, episodeList) => {
    // Perform validation checks
    // Return true if valid, false otherwise
    let isValid = true
    Object.values(episodeList).forEach((episodeData) => {
        // Program Main title should not be empty
        if (!episodeData.episodeTitle || episodeData.episodeTitle == "" || episodeData.episodeTitle.trim() === "") {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Episode Title is Mandatory' })
            isValid = false
        }
        if (!episodeData.episodeNumber && episodeData.episodeNumber <= 0) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Episode Number is Mandatory' })
            isValid = false
        }
        if (!episodeData.episodeSeries && episodeData.episodeSeries <= 0) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Series Number is Mandatory' })
            isValid = false
        }
        if (episodeData.episodeDuration === "" || episodeData.episodeDuration < 0) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Duration is Mandatory' })
            isValid = false
        }
        if (episodeData.episodeYear === "" || episodeData.episodeYear <= 0) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Year Produced is Mandatory' })
            isValid = false
        }
    })
    return isValid
}