import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { Dropdown } from 'primereact/dropdown'
import { ScrollPanel } from 'primereact/scrollpanel'
import { SplitButton } from 'primereact/splitbutton'
import { Toast } from 'primereact/toast'
import { string } from 'modules'
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import { clearProgrammeDraftResultsAction } from 'components/Body/data-entry/AudioVisual/store/actions'


// ReSharper disable once InconsistentNaming
const EditTitles = (props) => {
    //Deconstruct props
    const {
        showPanel,
        setShowPanel,
        primarytitle,
        languages,
        selectTitles,
        setTitlestoStore
    } = props

    const toast = useRef(null)
    const dispatch = useDispatch()
    const setAcceptedTitles = (titlesToAccept) => dispatch(setTitlestoStore(titlesToAccept))

    const presentationTitles = useSelector(selectTitles)
    const [titles, setTitles] = useState([])
    const [draftTitles, setDraftTitles] = useState({})
    const [primaryTitle, setPrimaryTitle] = useState('')
    const [isPrimaryTitleInvalid, setIsPrimaryTitleInvalid] = useState(false)
    const [areTitlesInvalid, setAreTitlesInvalid] = useState([])
    const [areLanguagesInvalid, setAreLanguagesInvalid] = useState([])

    const setInitialTitles = (resultTitles) => {
        const titlesArray = Object.values(resultTitles)
        const getPrimary = titlesArray.find(title => title.titleTypeId === 1)
        setPrimaryTitle(getPrimary ? getPrimary.title : '')
        const getSecondary = titlesArray.filter(title => title.titleTypeId !== 1)
        setTitles(getSecondary)
        setIsPrimaryTitleInvalid(false)
        setAreTitlesInvalid([])
        setAreLanguagesInvalid([])
    }

    //TODO: Add selector
    useEffect(() => {
        if (presentationTitles && (primaryTitle === '' || Object.keys(draftTitles).length === 0)) {
            setInitialTitles(presentationTitles)
            if (Object.keys(presentationTitles).length === 1) {
                const newTitle = { title: '', languageId: null, titleTypeId: 2 }
                setTitles(prevTitles => [...prevTitles, newTitle])
            }
        }
        else if (!presentationTitles) {
            const newTitle = { title: '', languageId: null, titleTypeId: 2 }
            setTitles(prevTitles => [...prevTitles, newTitle])
        }
    }, [presentationTitles])

    //Accept Split Buttom Items
    const saveModalItems = [
        {
            label: 'Accept Changes',
            icon: 'fa-light fa-check',
            command: () => {
                if (!validateChanges()) {
                    return
                }
                toast.current.show({ severity: 'success', summary: 'Saved', detail: 'Changes accepted successfully', life: 3000 })
            }
        },
        {
            label: 'Save Changes and Exit',
            icon: 'fa-light fa-check',
            command: () => {
                if (!validateChanges()) {
                    return
                }
                toast.current.show({ severity: 'success', summary: 'Saved', detail: 'Changes accepted successfully' })
                setTimeout(() => {
                    setShowPanel(false);
                }, 500)
            }
        }
    ]
    //On direct click of Accept in split button
    const splitSave = () => {
        if (!validateChanges()) {
            return
        }
        if (!draftTitles || Object.keys(draftTitles).length === 0) {
            return
        }
        else {

            toast.current.show({ severity: 'success', summary: 'Saved', detail: 'Changes accepted successfully', life: 3000 })
        }
    }

    //Cancel Save 

    const showCancelConfirm = (event) => {
        if (draftTitles && Object.keys(draftTitles).length > 0) {
            confirmPopup({
                target: event.currentTarget,
                message: 'You have unsaved changes. Do you want to discard them?',
                icon: 'pi pi-info-circle',
                defaultFocus: 'reject',
                acceptClassName: 'p-button-danger',
                accept
            })
        }
        else {
            setShowPanel(false)
        }
    }

    const accept = () => {       
        //todo: No need to revert to original, remove titles from draft
        setInitialTitles(presentationTitles) //revert to original on cancel
        setDraftTitles({}) //clear draft titles
        dispatch(clearProgrammeDraftResultsAction()) //remove draft titles from store
        toast.current.show({ severity: 'info', summary: 'Confirmed', detail: 'Pending changes are cancelled', life: 3000 })
        setTimeout(() => {
                      setShowPanel(false) 
                      }, 500) //close the panel
    }

    const isGuid = (id) => {
        const guidRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/
        return guidRegex.test(id)
    }

    /** Validattions **/

    const removeDuplicates = (array) => {
        const seen = new Set()
        return array.filter(item => {
            if (seen.has(item.title)) {
                return false
            } else {
                seen.add(item.title);
                return true
            }
        })
    }

    const removeEmptyRows = (array) => {
        return array.filter(title => {
            if (!title.title && !title.languageId) {
                return false
            }
            return true
        })
    }

    const validateChanges = () => {
        let isValid = true

        // Check if primary title is empty
        if (string.isNullOrWhitespace(primaryTitle)) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Main title cannot be empty' })
            setIsPrimaryTitleInvalid(true)
            isValid = false
        } else {
            setIsPrimaryTitleInvalid(false)
        }

        const nonEmptyTitles = removeEmptyRows(titles)
        if (nonEmptyTitles.length > 0) {
            setTitles(nonEmptyTitles)

            const uniqueTitles = removeDuplicates(nonEmptyTitles)
            setTitles(uniqueTitles)

            // Check if all titles have title filled...
            const invalidTitles = uniqueTitles.map((title, index) => {
                if (string.isNullOrWhitespace(title.title)) {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: `Title at index ${index} must have both language and title filled` })
                    return true
                }
                return false
            })
            // If any titles are invalid, the whole record is invalid
            setAreTitlesInvalid(invalidTitles)
            if (invalidTitles.some(invalid => invalid)) {
                isValid = false
            }
            // Language Id must be filled too
            const invalidLanguages = uniqueTitles.map((title, index) => {
                if (!title.languageId) { 
                    toast.current.show({ severity: 'error', summary: 'Error', detail: `Language at index ${index} must have both language and title filled` })
                    return true
                }
                return false
            })
            setAreLanguagesInvalid(invalidLanguages)
            if (invalidLanguages.some(invalid => invalid)) {
                isValid = false
            }
        }
        else {
            if (titles.length > 1) {
                setTitles([titles[0]]);
            }
        }

        // If all validations pass
        return isValid
    }

    //Add New Row

    const addNewRow = (index) => {
        const newTitle = { title: '', languageId: null, titleTypeId: 2 }
        setTitles(prevTitles => [
            ...prevTitles.slice(0, index + 1),
            newTitle,
            ...prevTitles.slice(index + 1)
        ])
        toast.current.show({ severity: 'info', summary: 'Add', detail: 'New Row Added' })
    }

    //Delete Row

    const deleteRow = (index) => {        
        const newTitles = titles.filter((_, i) => i !== index)
        setTitles(newTitles)
        setDraftTitles(newTitles)
        const mainTitle = presentationTitles?.find(title => title.titleTypeId === 1)
        const combinedTitles = mainTitle ? [mainTitle, ...newTitles] : newTitles
        setAcceptedTitles(combinedTitles)
    }

    //Add and Delete Row split button options

    const buttonModelItems = [
        {
            label: 'Add',
            icon: 'fa-thin fa-plus',
            command: (index) => addNewRow(index)
        },
        {
            label: 'Delete',
            icon: 'fa-light fa-minus',
            command: (index) => deleteRow(index)
        }
    ]

    const buttonModelItemsWithIndex = (index) => buttonModelItems.map(item => {
        return { ...item, command: () => item.command(index) }
    })

    //On change of Main Title
    const handleMainTitleChange = (e) => {
        const newTitle = e.target.value
        const titlesArray = Object.values(presentationTitles)
        const originalObjectIndex = titlesArray.findIndex(title => title.titleTypeId === 1);
        const newTitles = titlesArray.map((title, i) => {
            if (i === originalObjectIndex) {

                return {
                    ...title,
                    title: newTitle
                }
            }
            return title
        })        
        setPrimaryTitle(newTitle)
        setAcceptedTitles(newTitles)
        setDraftTitles(newTitles)
        return undefined
    }


    //On change of Title and Language

    const handleInputChange = (index, field, value) => {
        const newTitles = titles.map((title, i) => {
            if (i === index) {

                return {
                    ...title,
                    [field]: value
                }
            }
            return title
        })
        setTitles(newTitles)
        const mainTitle = presentationTitles?.find(title => title.titleTypeId === 1)
        const combinedTitles = mainTitle ? [mainTitle, ...newTitles] : newTitles
        setAcceptedTitles(combinedTitles)
        setDraftTitles(combinedTitles)

    }

    //Footer for Dialog

    const footer = (
        <div className="app-footer">
            <SplitButton className='green mr-2 mt-2' label="Accept" icon="fa-light fa-check" onClick={splitSave} dropdownIcon="fa-light fa-chevron-down" model={saveModalItems} />
            <Button label="Cancel" icon="fa-light fa-xmark" className="yellow mt-2 mr-3 " raised onClick={showCancelConfirm} ></Button>
        </div>
    )

    return (
        <Dialog
            header={<div><span>Edit Titles: </span><span style={{ fontWeight: 'bold' }}>{primarytitle && primarytitle.title}</span></div>}
            visible={showPanel}
            style={{ width: '40vw' }}
            footer={footer}
            onHide={() => setShowPanel(false)}
            closable={true}
            maximizable={false}
            modal
            draggable={false}
            id="edit-titles-dialog"
        >
            <div className="sub-panel">
                <Toast ref={toast} />
                <ConfirmPopup />
                <div id="main-title">
                    <p className="label-input mb-1">Main Title</p>
                    <div className="d-flex align-items-center">
                        <InputText id="maintitle" value={primaryTitle} onChange={handleMainTitleChange} invalid={isPrimaryTitleInvalid} className=" av-radio-option form-control mb-3" style={{ width: '90%' }} />
                    </div>
                </div>
                <fieldset className="mt-2">
                    <legend>Other Titles</legend>
                    <div className="other-titles">
                        <ScrollPanel className="mt-2" style={{ height: '300px' }}>
                            {titles && (titles.map((title, index) => (
                                <div key={index} className="row mb-2 dotted-separator" >
                                    <div className="col-md-5">
                                        <p className="label-input mb-1">Language</p>
                                        <Dropdown id="language" value={titles && titles[index] && titles[index].languageId} options={languages} filter onChange={(e) => handleInputChange(index, 'languageId', e.value.id)} placeholder={titles[index].languageId && languages.find(lang => lang.id === titles[index].languageId)?.name}
                                            className="custom-full-width mb-2" style={{ height: '53%' }} invalid={areLanguagesInvalid[index]} optionLabel="name"
                                            optionValue="id" />
                                    </div>
                                    <div className="col-md-5">
                                        <p className="label-input mb-1">Title</p>
                                        <InputText id="title" value={titles && titles[index] && titles[index].title} onChange={(e) => handleInputChange(index, 'title', e.target.value)} className=" av-radio-option form-control mb-2 custom-full-width" invalid={areTitlesInvalid[index]} />
                                    </div>
                                    <div className="col-md-2">
                                        <SplitButton className='modal-only mt-4' rounded onClick={splitSave} dropdownIcon="fa-light fa-plus-minus" model={buttonModelItemsWithIndex(index)} />
                                    </div>
                                </div>
                            )))}

                        </ScrollPanel>
                    </div>
                </fieldset>
            </div>
        </Dialog >
    )
}
export default EditTitles;
