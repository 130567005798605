import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Tag } from 'primereact/tag'
import { Button } from 'primereact/button'
import { setProgrammeDraftResultsTitlesAction } from 'components/Body/data-entry/AudioVisual/store/actions'
import { selectIsNewProgramme, selectAudioVisualResultPresentationTitles } from 'components/Body/data-entry/AudioVisual/store/selectors'
import { InputText } from 'primereact/inputtext'
import { ScrollPanel } from 'primereact/scrollpanel'
import EditTitles from 'components/Body/data-entry/AudioVisual/EditTitles'
import { selectAllAssets } from 'providers/AssetLoader/store/selectors'


// ReSharper disable once InconsistentNaming
export const ProgrammeDetailsHeader = ({ primaryTitle, altTitles, resultsDataForEdit }) => {
    const dispatch = useDispatch()
    const isNewProgramme = useSelector(selectIsNewProgramme)
    const { languages } = useSelector(selectAllAssets)
    const assetLanguages = languages
    const [showPanel, setShowPanel] = useState(false)
    const newProgrammePlaceholder = "New Programme"
    
    //On change of Main Title
    const handleMainTitleChange = (e) => {
        const newTitle = e.target.value
        const titlesArray = Object.values(resultsDataForEdit.titles)
        const originalObjectIndex = titlesArray.findIndex(title => title.titleTypeId === 1);
        titlesArray[originalObjectIndex].title = newTitle
        dispatch(setProgrammeDraftResultsTitlesAction(titlesArray))
        return undefined
    }
    
    
    return (
        <div className='details-header'>
            <div className="header mb-2"><span class="title">Prog. Code: {resultsDataForEdit && resultsDataForEdit.sapCode ? resultsDataForEdit.sapCode : 'Pending'}</span></div>
            {isNewProgramme ? (
                <div className='programme-title'>
                    <InputText className="p-inputtext-lg"
                        placeholder={newProgrammePlaceholder}
                        value={primaryTitle && primaryTitle.title}
                        onChange={handleMainTitleChange} />
                </div>
            ) : (
                <div className='programme-title'>{primaryTitle && primaryTitle.title}</div>
            )}
            <fieldset>
                <legend>Other Titles</legend>
                <div className="other-titles-content">
                    <ScrollPanel className="mt-1" style={{ height: '60px' }}>
                        {altTitles && (altTitles.map((title, index) => (
                            <div key={index} className="mb-2"> <Tag value={title.languageName && title.languageName.substring(0, 2).toLowerCase()} className="mr-2"></Tag>{title.title}</div>
                        )))}
                    </ScrollPanel>
                    <Button label="Edit Titles" icon="fa-light fa-pen" className="sage mt-4 button-left-align " onClick={() => setShowPanel(true)} raised />
                    <EditTitles showPanel={showPanel} setShowPanel={setShowPanel} primarytitle={primaryTitle} languages={assetLanguages} selectTitles={selectAudioVisualResultPresentationTitles} setTitlestoStore={setProgrammeDraftResultsTitlesAction} />
                </div>
            </fieldset>
        </div>
    )
}
