import { FilterMatchMode } from 'primereact/api'

export const matchConstraints = (value, filter) => {
    if (!filter || !Array.isArray(filter.constraints) || filter.constraints.length === 0) return true

    const { operator, constraints } = filter

    if (operator === 'and') {
        return constraints.every(constraint => {
            if (constraint.value === null) return true
            return matchFilter(value, constraint.value, constraint.matchMode)
        })
    } else if (operator === 'or') {
        return constraints.some(constraint => {
            if (constraint.value === null) return true
            return matchFilter(value, constraint.value, constraint.matchMode)
        })
    }

    return true
}

export const matchFilter = (value, filterValue, matchMode) => {
    if (value == null) return false;
    switch (matchMode) {
        case FilterMatchMode.STARTS_WITH:
            return value.startsWith(filterValue)
        case FilterMatchMode.CONTAINS:
            return value.includes(filterValue)
        case FilterMatchMode.EQUALS:
            return value === filterValue
        case FilterMatchMode.NOT_EQUALS:
            return value !== filterValue
        case FilterMatchMode.ENDS_WITH:
            return value.endsWith(filterValue)
        case FilterMatchMode.LESS_THAN:
            return value < filterValue
        case FilterMatchMode.LESS_THAN_OR_EQUAL:
            return value <= filterValue
        case FilterMatchMode.GREATER_THAN:
            return value > filterValue
        case FilterMatchMode.GREATER_THAN_OR_EQUAL:
            return value >= filterValue
        case FilterMatchMode.CUSTOM:
            return value?.trim() === filterValue?.trim()
        default:
            return false
    }
}
