import React from 'react'
import { useSelector } from 'react-redux'
import { ProgrammeDetailsHeader } from 'components/Body/data-entry/AudioVisual/ProgrammeDetailsHeader'
import { ProgrammeDetailsContent } from 'components/Body/data-entry/AudioVisual/ProgrammeDetailsContent'
import { ProgrammeDetailsFooter } from 'components/Body/data-entry/AudioVisual/ProgrammeDetailsFooter'
import { selectIsNewProgramme, selectAudioVisualResultOriginal } from 'components/Body/data-entry/AudioVisual/store/selectors'

// ReSharper disable once InconsistentNaming
export const ProgrammeDetails = (props) => {
    const isNewProgramme = useSelector(selectIsNewProgramme)
    //Deconstruct props
    const {
        className,
        toast,
        programmeGuid,
        showPanel,
        setShowPanel,
        primaryTitle,
        altTitles,
        episodePrimaryTitle,
        resultsDataForEdit,
        isFilm
    } = props
    const programmeDataOriginal = useSelector(selectAudioVisualResultOriginal)
    let primaryCountry = Object.values(resultsDataForEdit?.countries || {}).find(country => country.isPrimary === true)
    const { programmeTypeId, formatId } = resultsDataForEdit || {}         
    let episodeValidationRequired = isNewProgramme || isFilm
    return (
        <div className={className}>
            <ProgrammeDetailsHeader showPanel={showPanel} setShowPanel={setShowPanel} primaryTitle={primaryTitle} altTitles={altTitles}  resultsDataForEdit={resultsDataForEdit} />
            <ProgrammeDetailsContent resultsDataForEdit={resultsDataForEdit} programmeDataOriginal={programmeDataOriginal} toast={toast}/>
            <ProgrammeDetailsFooter programmeGuid={programmeGuid} isNewProgramme={isNewProgramme} toast={toast} primaryTitle={primaryTitle} episodePrimaryTitle={episodePrimaryTitle} programmeTypeId={programmeTypeId} formatId={formatId} primaryCountry={primaryCountry} episodeValidationRequired={episodeValidationRequired} resultsDataForEdit={resultsDataForEdit} programmeDataOriginal={programmeDataOriginal} isFilm={isFilm} />
        </div>

    )
}